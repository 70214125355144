const state = {
  selected: null,
  documents: [],
};

const getters = {
  templates: (state) => state.selected.administrations.filter((a) => a.category === 'template'),
};

const actions = {
  LOAD_DOCUMENT_INFO(context, { ids }) {
    return this.$axios
      .get('/api/document/infos', { params: { ids: ids } })
      .then((r) => r.data);
  },
  LOAD_DOCUMENTS(context, project_id) {
    return this.$axios
      .get(`/api/document/project/${project_id}`)
      .then((result) => context.commit('SET_DOCUMENTS', result.data));
  },
  ADD_DOCUMENT(context, { file, project_id }) {
    const formData = new FormData();
    formData.append('file', file);
    return this.$axios.post(
      `/api/document/project/${project_id}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    ).then((result) => context.commit('ADD_DOCUMENT', result.data));
  },
  REMOVE_DOCUMENT(context, document_id) {
    return this.$axios.delete(`/api/document/${document_id}`)
      .then(() => context.commit('REMOVE_DOCUMENT', document_id));
  },
};

const mutations = {
  SET_SELECTED(state, project) {
    state.selected = project;
  },
  SET_DOCUMENTS(state, documents) {
    state.documents = documents;
  },
  ADD_DOCUMENT(state, document) {
    state.documents.push(document);
  },
  REMOVE_DOCUMENT(state, document_id) {
    const index = state.documents.findIndex((d) => d.id === document_id);
    state.documents.splice(index, 1);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
