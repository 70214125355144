const state = {
  message: null,
  type: 'info',
  batch: null,
  enabledBatch: false,
  timeoutBatch: 2000,
  idTimeout: null,
};
const getters = {
  batch: (state) => state.batch,
  enabledBatch: (state) => state.enabledBatch,
  timeoutBatch: (state) => state.timeoutBatch,
};

const mutations = {
  CLEAR_BATCH(state) {
    state.timeoutBatch = 2000;
    state.enabledBatch = false;
    state.batch = null;
  },
  SET_BATCH(state, batch) {
    state.batch = batch;
    state.timeoutBatch = -1;
    state.enabledBatch = true;
  },
  UPDATE_NOTIFICATION(state, data) {
    state.message = data.message;
    state.type = data.type;
  },
  CLEAR_NOTIFICATION(state) {
    state.message = null;
  },
  SET_ID_TIMEOUT(state, timeout) {
    state.idTimeout = timeout;
  },
};

const actions = {
  FIRE_SUCCESS(context, message) {
    context.dispatch('FIRE_NOTIFICATION', {
      message,
      type: 'success',
    });
  },
  FIRE_ERROR(context, message) {
    context.dispatch('FIRE_NOTIFICATION', {
      message,
      type: 'error',
    });
  },
  FIRE_DEV(context, message) {
    context.dispatch('FIRE_NOTIFICATION', {
      message: `DEV MESSAGE : ${message}`,
      type: 'dev',
    });
  },
  FIRE_NOTIFICATION(context, data) {
    context.commit('UPDATE_NOTIFICATION', data);
    setTimeout(() => {
      context.commit('CLEAR_NOTIFICATION');
    }, 5000);
  },
  CANCEL_BATCH(context) {
    if (context.state.batch) {
      this.$axios.post(`/api/batch/cancel/${context.state.batch.id}`)
        .then(() => {
          clearTimeout(context.state.idTimeout);
          context.commit('SET_ID_TIMEOUT');
          context.commit('CLEAR_BATCH');
        });
    }
  },
  WAITING_BATCH(context, { batchId, next }) {
    const idTimeout = setTimeout(() => {
      this.$axios.get(`/api/batch/${batchId}`)
        .then((r) => {
          context.commit('SET_BATCH', r.data);
          if ((r.data.processedJobs + r.data.failedJobs) < r.data.totalJobs) {
            context.dispatch('WAITING_BATCH', { batchId: r.data.id, next: next });
          } else {
            if (next) {
              context.dispatch(next.action, next.payload, { root: true });
            }
            context.commit('CLEAR_BATCH');
          }
        });
    }, 1000);
    context.commit('SET_ID_TIMEOUT', idTimeout);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
