import { some, uniq } from 'lodash';
import { i18n } from '@/main';
import { forceFileDownload } from '@/helper/file';

const state = {
  selected: [],
  states: [],
  sections: [],
  parcelles: [],
  updateTree: '',
  doNotFitMapAfterReload: false,
};

const getters = {
  states: (state) => state.states,
  sectionById: (state) => state.sections.reduce((a, b) => (((a[b.id] = b.name), a)), {}),
  stateName: (state) => state.states.reduce((a, b) => (((a[b.id] = b.name), a)), {}),
  stateColor: (state) => state.states.reduce((a, b) => (((a[b.id] = b.color), a)), {}),
  stateCumulColor: (state) => state.states.reduce((a, b) => (((a[b.id] = b.cumulColor), a)), {}),
  selectedState: (state) => uniq(
    state.parcelles
      .filter((p) => (p.actual_state != null && state.selected.includes(p.id)))
      .map((p) => p.actual_state.state),
  ),
  parcelleColors: (state, getters) => (id) => {
    const parcelle = state.parcelles.find((p) => p.id === id);
    if (state.selected.indexOf(parcelle.id) > -1) return 'green';
    if (parcelle.actual_state.state === 'inventaire') {
      if (!some(parcelle.states, (s) => s.step === 'import_inventaire')) {
        return 'red';
      }
    }
    return getters.stateColor[parcelle.actual_state.state] ?? 'black';
  },
  doNotFitMapAfterReload: (state) => state.doNotFitMapAfterReload,
};

const mutations = {
  SET_LOAD_OPTIONS(state, options) {
    if (options) {
      state.doNotFitMapAfterReload = options.doNotFitMapAfterReload ?? false;
    }
  },
  UPDATE_SECTIONS(state, sections) {
    state.sections = sections;
    state.updateTree = new Date().getTime();
  },
  ADD_SECTION(state, section) {
    const index = state.sections.findIndex((s) => s.id === section.id);
    if (index < 0) {
      state.sections.push(section);
    }
    state.updateTree = new Date().getTime();
  },
  VALIDATE_PARCELLE_AFFECTATION(state, affectValidation) {
    const index = state.sections.findIndex(
      (s) => s.id === affectValidation.section.id,
    );
    if (index < 0) {
      state.sections.push(affectValidation.section);
    }
    for (const parcelle of affectValidation.parcelles) {
      const index = state.parcelles.findIndex((p) => p.id === parcelle.id);
      if (index > -1) {
        state.parcelles[index].section_id = parcelle.section_id;
      }
    }
    state.updateTree = new Date().getTime();
  },
  SET_SELECTED(state, parcelle) {
    state.selected = parcelle;
  },
  ADD_SELECT_PARCELLE(state, id) {
    state.selected.push(id);
  },
  REMOVE_SELECT_PARCELLE(state, id) {
    const index = state.selected.indexOf(id);
    state.selected.splice(index, 1);
  },
  UPDATE_PARCELLES(state, parcelles) {
    state.parcelles = parcelles;
  },
  UPDATE_STATES(state, data) {
    state.states = data;
  },
};

const actions = {
  ADD_PARCELLE(context, { parcelle, papid, role }) {
    parcelle.project_id = context.rootState.app.selectedProject.id;

    return this.$axios.post('/api/parcelle/create', {
      parcelle: parcelle,
      pap_id: papid,
      role: role,
    }).then((r) => r.data);
  },
  AFFECT_SECTION(context, affectation) {
    return this.$axios
      .post(
        `/api/parcelle/section/${context.rootState.app.selectedProject.id}`,
        affectation,
      )
      .then((r) => {
        context.commit('VALIDATE_PARCELLE_AFFECTATION', r.data);
      });
  },
  EXPORT_TEST(context, pIds) {
    return this.$axios
      .post(
        `/api/parcelle/export_test/${context.rootState.app.selectedProject.id}`,
        { parcelle_ids: pIds },
      )
      .then((r) => {
        console.log(r.data);
      });
  },
  EXPORT_ACTION_WITH_BATCH(context, data) {
    return this.$axios
      .post(
        `/api/parcelle/${data.action}/${context.rootState.app.selectedProject.id}`,
        {
          parcelle_ids: data.parcelle_ids,
          template_id: data.template_id,
        },
      )
      .then((response) => {
        if (response.data.batch_id) {
          context.dispatch(
            'WAITING_BATCH',
            {
              batchId: response.data.batch_id,
              next: { action: 'document/DOWNLOAD', payload: { id: response.data.document_id } },
            },
            { root: true },
          );
        }
        return response;
      });
  },
  EXPORT_ACTION(context, data) {
    return this.$axios
      .post(
        `/api/parcelle/${data.action}/${context.rootState.app.selectedProject.id}`,
        {
          parcelle_ids: data.parcelle_ids,
          template_id: data.template_id,
        },
        { responseType: 'arraybuffer' },
      )
      .then((response) => {
        const header = response.headers['content-disposition'];
        let filename = header.match(/filename="?(.+)"?/)[1];
        filename = filename.replace('"', '');
        forceFileDownload(response, filename);
        return response;
      });
  },
  IMPORT_PARCELLAIRE(context, data) {
    const project_id = data.project_id ?? context.rootState.app.selectedProject.id;
    return this.$axios.post(
      `/api/administration/import/parcellaire/${project_id}`,
      data.form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  LOAD_PARCELLE(context, { parcelle_id, params }) {
    return this.$axios.get(`/api/parcelle/data/${parcelle_id}`, { params })
      .then((result) => result.data);
  },
  LOAD_PARCELLES(context, options) {
    return this.$axios
      .get(`/api/parcelle/all/${context.rootState.app.selectedProject.id}`)
      .then((r) => {
        if (options) {
          context.commit('SET_LOAD_OPTIONS', options);
        }
        context.commit('UPDATE_PARCELLES', r.data);
        return r.data;
      });
  },
  DISABLE_LOAD_OPTIONS(context) {
    context.commit('SET_LOAD_OPTIONS', { doNotFitMapAfterReload: false });
  },
  TOGGLE_SELECT_PARCELLE(context, parcelle) {
    const index = context.state.selected.indexOf(parcelle.id);
    if (index < 0) context.commit('ADD_SELECT_PARCELLE', parcelle.id);
    else context.commit('REMOVE_SELECT_PARCELLE', parcelle.id);
  },
  UPDATE_SECTION(context) {
    if (context.rootState.app.selectedProject) {
      this.$axios
        .get(
          `/api/parcelle/section/${context.rootState.app.selectedProject.id}`,
        )
        .then((r) => {
          context.commit('UPDATE_SECTIONS', r.data);
        });
    } else {
      context.commit('UPDATE_SECTIONS', []);
    }
  },
  ENSURE_PARCELLE_STATE(context, payload) {
    return this.$axios.post(
      `/api/parcelle/${payload.get('parcelle_id')}/target_state/${payload.get('target')}`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  async INIT(context) {
    context.dispatch('UPDATE_SECTION');

    const states = [
      {
        id: 'inventaire',
        name: i18n.t('reinstallation.gestion_du_par.inventaire'),
        color: '#1172af',
        cumulColor: '#1172af',
      },
      {
        id: 'validation',
        name: i18n.t('reinstallation.gestion_du_par.validation'),
        color: '#fdac00',
        cumulColor: '#fdac00',
      },
      {
        id: 'negociation',
        name: i18n.t('reinstallation.gestion_du_par.negociation'),
        color: 'rgba(19,250,36,0.76)',
        cumulColor: 'rgba(19,250,36,0.76)',
      },
      {
        id: 'contractualise',
        name: i18n.t('reinstallation.gestion_du_par.contractualise'),
        color: '#5dac00',
        cumulColor: '#5dac00',
      },
      {
        id: 'paye',
        name: i18n.t('reinstallation.gestion_du_par.paye'),
        color: '#5dfcde',
        cumulColor: '#5dfcde',
      },
      {
        id: 'no_state', name: i18n.t('reinstallation.gestion_du_par.aucun'), color: '#ef4e0c', cumulColor: '#ef4e0c',
      },
    ];
    context.commit('UPDATE_STATES', states);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
