export const organisation_es = {
  projets: 'Proyectos',
  parameters: '(ES)Parameters',
  mapURL: 'URL Tile',
  mapTileOptions: 'Options',
  mapAttribution: 'Attribution',
  mapParameter: '(ES)Cartographie',
  creer_un_projet: 'Crear un proyecto',
  nom_projet: 'Nombre del proyecto',
  choix_du_plugin: 'Elección del plugin',
  matrice_de_prix: 'Matriz de precios',
  parcellaire: 'Parcela de terreno',
  nom_du_formulaire: 'Nombre del formulario',
  selectionnez_votre_fichier: 'Seleccione su archivo',
  questionnaire: 'Cuestionario',
  ok: 'OK',
  le_projet_a_ete_cree: 'El proyecto fue creado',
  erreur_creation_projet: 'Error al crear el proyecto',
  annuler: 'Cancelar',
  utilisateurs: 'Usuarios',
  creer: 'Crear',
  editer: 'Editar',
  un_utilisateur: 'Un usuario',
  password: 'Contraseña',
  password_min_characters: '(ES)Le mot de passe doit contenir minimum 8 caracteres',
  email: 'Envíe un correo electrónico a',
  label: 'Etiqueta',
  id: 'ID',
  nom: 'Nombre',
  plugin: 'Plug-in',
  user: 'Usuario',
  administrator: 'Director',
  superadmin: 'Super Director',
  langage: 'Idioma',
  modules_disponible_pour_votre_organisation: 'Módulos disponibles para su organización',
  definir_les_modules_pour_ce_projet: 'Definir los módulos de este proyecto',
  enregistrer: 'Registro',
  les_modules_affectes_au_projet_ont_ete_mis_a_jour: 'Se han actualizado los módulos asignados al proyecto',
  les_modules_affectes_au_projet_n_ont_pas_ete_mis_a_jour: 'Los módulos asignados al proyecto no han sido actualizados',
  restreindre_les_modules_pour_cet_utilisateur: 'Restringir los módulos para este usuario',
  laissez_les_choix_par_defaut: 'Deje las opciones por defecto si quiere que el usuario tenga acceso a todos los módulos del proyecto',
  les_modules_restreints_a_utilisateur_n_ont_pas_ete_mis_a_jour: 'Los módulos restringidos a los usuarios no han sido actualizados',
  les_modules_restreints_a_utilisateur_ont_ete_mis_a_jour: 'Se han actualizado los módulos restringidos a los usuarios',
  modules: 'Módulos disponibles para su organización',
  role: 'Papel',
  create_test: '(ES)Données de test',
};
