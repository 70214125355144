<template>
  <v-snackbar color="primary" bottom :value="enabledBatch" :timeout="timeoutBatch">
      <div v-if="batch" class="d-flex">
        <div class="d-inline ma-2 justify-center">
          <span>{{ batch.name }}</span>
          <v-progress-linear color="white" :value="batch.progress"></v-progress-linear>
          <span class="justify-self-center" style="width: 100%" >{{ batch.processedJobs }} / {{ batch.totalJobs }}</span>
        </div>
        <div class="align-self-center justify-self-center ma-auto">
          <v-icon
              @click="cancelBatch"
              color="white">mdi-close-circle
          </v-icon>
      </div>
      </div>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OverlayBatch',
  computed: {
    ...mapGetters(['batch', 'enabledBatch', 'timeoutBatch']),
  },
  methods: {
    cancelBatch() {
      this.$store.dispatch('CANCEL_BATCH');
    },
  },
};
</script>

<style scoped>

</style>
